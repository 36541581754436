const moduleRoute = require("./module.js").route;

export default [
  {
    type: "folder",
    name: "FCI"
  },
  {
    type: "route",
    name: "File Uploads",
    description: "File Uploads",
    icon: "mdi-file-upload",
    url: moduleRoute + "/uploads",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  }
];
