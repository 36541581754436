const moduleRoute = require("./module.js").route;

export default [
  {
    type: "folder",
    name: "System Administration"
  },
  {
    type: "route",
    name: "Webhooks",
    description: "Webhooks",
    icon: "mdi-webhook",
    url: moduleRoute + "/webhooks",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Queries",
    description: "Queries",
    icon: "mdi-database-search",
    url: moduleRoute + "/queries",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Lists",
    description: "Lists",
    icon: "mdi-playlist-star",
    url: moduleRoute + "/lists",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Events",
    description: "Events",
    icon: "mdi-calendar-clock",
    url: moduleRoute + "/events",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Messages",
    description: "Messages",
    icon: "mdi-message",
    url: moduleRoute + "/messages",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Documents",
    description: "Documents",
    icon: "mdi-file-document-outline",
    url: moduleRoute + "/documents",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Services",
    description: "Registered Services",
    icon: "mdi-code-string",
    url: moduleRoute + "/services",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Metrics",
    description: "Published Logs",
    icon: "mdi-clipboard-pulse",
    url: moduleRoute + "/metrics",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  }
  // {
  //   type: "route",
  //   name: "Logs",
  //   description: "Published Logs",
  //   icon: "mdi-view-headline",
  //   url: moduleRoute + "/logs",
  //   location: {
  //     menu: true,
  //     toolbar: false,
  //     home: true
  //   }
  // }
];
