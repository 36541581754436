<template>
  <IconBase viewBox="0 0 24 24">
    <g id="Layer_1-2">
      <g id="edit">
        <path id="square-limit" class="cls-1" d="m0,0h24v24H0V0Z" />
        <path
          class="cls-2"
          d="m22.19,6.65c0,.66-.53,1.19-1.19,1.19h-12.45c-.66,0-1.19-.53-1.19-1.19s.53-1.19,1.19-1.19h12.45c.66,0,1.19.53,1.19,1.19h0Z"
        />
        <path
          class="cls-2"
          d="m5.77,6.65c0,1.1-.89,1.98-1.98,1.98s-1.98-.89-1.98-1.98.89-1.98,1.98-1.98,1.98.89,1.98,1.98"
        />
        <path
          class="cls-2"
          d="m22.19,12c0,.66-.53,1.19-1.19,1.19h-12.45c-.66,0-1.19-.53-1.19-1.19s.53-1.19,1.19-1.19h12.45c.66,0,1.19.53,1.19,1.19Z"
        />
        <path
          class="cls-2"
          d="m5.77,12c0,1.1-.89,1.98-1.98,1.98s-1.98-.89-1.98-1.98.89-1.98,1.98-1.98,1.98.89,1.98,1.98"
        />
        <path
          class="cls-2"
          d="m22.19,17.35c0,.66-.53,1.19-1.19,1.19h-12.45c-.66,0-1.19-.53-1.19-1.19s.53-1.19,1.19-1.19h12.45c.66,0,1.19.53,1.19,1.19Z"
        />
        <path
          class="cls-2"
          d="m5.77,17.35c0,1.1-.89,1.98-1.98,1.98s-1.98-.89-1.98-1.98.89-1.98,1.98-1.98,1.98.89,1.98,1.98"
        />
      </g>
    </g>
  </IconBase>
</template>

<script>
  import IconBase from "./IconBase.vue";

  export default {
    components: { IconBase }
  };
</script>

<style>
  .cls-1 {
    fill: none;
  }

  .cls-2 {
    fill: currentColor;
  }
</style>
