const moduleRoute = require("./module.js").route;

export default [
  {
    type: "folder",
    name: "Risk"
  },
  {
    type: "route",
    name: "Criteria",
    description: "Criteria",
    icon: "mdi-feature-search",
    url: moduleRoute + "/criteria",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  }
];
