const moduleRoute = require("./module.js").route;

export default [
  {
    type: "folder",
    name: "Cargo Management"
  },
  {
    type: "route",
    name: "Cargo Voyages",
    description: "Manage Cargo Voyages",
    icon: "mdi-transit-connection-variant",
    url: moduleRoute + "/voyages",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "folder",
    name: "Port Calls",
    description: "Manage Port Calls",
    icon: "mdi-pier-crane",
    children: [
      {
        type: "route",
        name: "Create New Port Call",
        shortName: "New Port Calls",
        description: "New Port Calls",
        icon: "mdi-plus",
        url: moduleRoute + "/portcalls/new",
        location: {
          menu: true,
          toolbar: false,
          home: true
        }
      },
      {
        type: "route",
        name: "Search Port Calls",
        shortName: "Search Port Calls",
        description: "Search Port Calls",
        icon: "mdi-magnify",
        url: moduleRoute + "/portcalls",
        location: {
          menu: true,
          toolbar: false,
          home: true
        }
      },
      // {
      //   type: "folder",
      //   name: "Ship Stores",
      //   description: "Manage Ship Stores",
      //   icon: "mdi-pier-crane",
      //   children: [
      // {
      //   type: "route",
      //   name: "Create New Port Call",
      //   shortName: "New Port Calls",
      //   description: "New Port Calls",
      //   icon: "mdi-plus",
      //   url: moduleRoute + "/shipstores/new",
      //   location: {
      //     menu: true,
      //     toolbar: false,
      //     home: true
      //   }
      // },
      {
        type: "route",
        name: "Search Ship Stores",
        shortName: "Search Ship Stores",
        description: "Search Ship Stores",
        icon: "mdi-magnify",
        url: moduleRoute + "/shipstores",
        location: {
          menu: true,
          toolbar: false,
          home: true
        }
      },
      {
        type: "route",
        name: "Search Passengers Document",
        shortName: "Search Passengers Document",
        description: "Search Passengers Document",
        icon: "mdi-magnify",
        url: moduleRoute + "/passengers",
        location: {
          menu: true,
          toolbar: false,
          home: true
        }
      },
      {
        type: "route",
        name: "Search Last Ten Ports Document",
        shortName: "Search Last Ten Ports Document",
        description: "Search Last Ten Ports Document",
        icon: "mdi-magnify",
        url: moduleRoute + "/lasttenports",
        location: {
          menu: true,
          toolbar: false,
          home: true
        }
      }
    ]
  },
  {
    type: "route",
    name: "Cargo declarations",
    description: "Manage Cargo Declarations",
    icon: "mdi-file-document",
    url: moduleRoute + "/declarations",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Consignments",
    description: "Manage Consignments",
    icon: "mdi-package",
    url: moduleRoute + "/consignments",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Transport Equipments",
    description: "Manage Transport Equipments",
    icon: "mdi-package-variant-closed",
    url: moduleRoute + "/equipments",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Containers",
    description: "Manage Containers",
    icon: "mdi-truck-outline",
    url: moduleRoute + "/containers",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Vessels",
    description: "Manage Vessels",
    icon: "mdi-ferry",
    url: moduleRoute + "/vessels",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Offices",
    description: "Manage Offices",
    icon: "mdi-office-building",
    url: moduleRoute + "/offices",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  }
];
