const moduleRoute = require("./module.js").route;

export default [
  {
    type: "route",
    name: "Home",
    description: undefined,
    icon: "mdi-home",
    url: moduleRoute + "/home",
    location: {
      menu: true,
      toolbar: false,
      home: false
    }
  },
  {
    type: "folder",
    name: "Dashboards",
    description: undefined,
    icon: "mdi-view-dashboard",
    url: moduleRoute + "/home",
    children: [
      {
        type: "route",
        name: "My Library",
        description: undefined,
        icon: "mdi-menu",
        url: moduleRoute + "/my-library",
        location: {
          menu: true,
          toolbar: false,
          home: false
        }
      },
      {
        type: "route",
        name: "My Tasks",
        description: "Access easily your tasks in the system",
        icon: "mdi-view-dashboard",
        url: moduleRoute + "/my-tasks",
        meta: {
          color: "red"
        },
        location: {
          menu: true,
          toolbar: false,
          home: true
        }
      }
    ]
  }
];
