class FilterUtils {
  getOrDefault(searchModelO) {
    let searchModel = searchModelO ? JSON.parse(JSON.stringify(searchModelO)) : undefined;
    if (!searchModel) {
      searchModel = {
        filter: {
          filterData: {}
        },
        options: {
          page: 1,
          itemsPerPage: -1
        }
      };
    }
    if (!searchModel["filter"]) {
      searchModel["filter"] = {
        filterData: {}
      };
    }
    if (!searchModel["filter"]["filterData"]) {
      searchModel["filter"]["filterData"] = {};
    }
    if (!searchModel["options"]) {
      searchModel["options"] = {
        page: 1,
        itemsPerPage: -1
      };
    }
    if (searchModel["options"]) {
      if (searchModel["options"]["page"] === undefined || searchModel["options"]["page"] <= 0) {
        searchModel["options"]["page"] = 1;
      }
      if (
        searchModel["options"]["itemsPerPage"] === undefined ||
        (searchModel["options"]["itemsPerPage"] <= 1 &&
          searchModel["options"]["itemsPerPage"] !== -1)
      ) {
        searchModel["options"]["itemsPerPage"] = 10;
      }
    }
    return searchModel;
  }
}

export default new FilterUtils();
