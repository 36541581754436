import en from "vee-validate/dist/locale/en.json";

let messages = { is: "The {_field_} document is not created" };

let out = {
  validations: {
    code: "en",
    messages: {
      ...en.messages,
      ...messages
    }
  }
};
export default {
  ...out
};
