var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("IconBase", { attrs: { viewBox: "0 0 24 24" } }, [
    _c("g", { attrs: { id: "Layer_1-2" } }, [
      _c("g", { attrs: { id: "edit" } }, [
        _c("path", {
          staticClass: "cls-1",
          attrs: { id: "square-limit", d: "m0,0h24v24H0V0Z" },
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            d: "m22.19,6.65c0,.66-.53,1.19-1.19,1.19h-12.45c-.66,0-1.19-.53-1.19-1.19s.53-1.19,1.19-1.19h12.45c.66,0,1.19.53,1.19,1.19h0Z",
          },
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            d: "m5.77,6.65c0,1.1-.89,1.98-1.98,1.98s-1.98-.89-1.98-1.98.89-1.98,1.98-1.98,1.98.89,1.98,1.98",
          },
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            d: "m22.19,12c0,.66-.53,1.19-1.19,1.19h-12.45c-.66,0-1.19-.53-1.19-1.19s.53-1.19,1.19-1.19h12.45c.66,0,1.19.53,1.19,1.19Z",
          },
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            d: "m5.77,12c0,1.1-.89,1.98-1.98,1.98s-1.98-.89-1.98-1.98.89-1.98,1.98-1.98,1.98.89,1.98,1.98",
          },
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            d: "m22.19,17.35c0,.66-.53,1.19-1.19,1.19h-12.45c-.66,0-1.19-.53-1.19-1.19s.53-1.19,1.19-1.19h12.45c.66,0,1.19.53,1.19,1.19Z",
          },
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            d: "m5.77,17.35c0,1.1-.89,1.98-1.98,1.98s-1.98-.89-1.98-1.98.89-1.98,1.98-1.98,1.98.89,1.98,1.98",
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }