const moduleRoute = require("./module.js").route;

export default [
  {
    type: "folder",
    name: "EORI Management"
  },
  {
    type: "route",
    name: "Registration and Identification",
    description: "Register Economic Operators",
    icon: "mdi-account-tie",
    url: moduleRoute + "/operators",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  }
];
