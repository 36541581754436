const homeLibrary = [
  {
    type: "route",
    name: "Home",
    description: "Navigate to homepage (this page)",
    icon: "mdi-home",
    url: "/home",
    meta: {
      isHome: true
    },
    location: {
      menu: false,
      toolbar: false,
      home: false
    }
  }
];

let authLibraryAdded = false;

const authLibrary = [
  {
    type: "sso",
    name: "Sign In",
    description: "Log in using your username and password",
    icon: "mdi-login",
    url: undefined,
    meta: {
      public: true,
      onlyNotAuth: true,
      isLogin: true
    },
    location: {
      menu: false,
      toolbar: true,
      home: true
    }
  },
  {
    type: "sso",
    name: "Manage Account",
    description: "Manage account One-Time-Password and other settings",
    icon: "mdi-account-settings",
    url: undefined,
    meta: {
      isManage: true,
      color: "red",
      light: false
    },
    location: {
      menu: true,
      toolbar: true,
      home: true
    }
  },
  {
    type: "sso",
    name: "Sign Out",
    description: "Logout from all sessions in this browser window",
    icon: "mdi-logout",
    url: undefined,
    meta: {
      isLogout: true,
      color: "red",
      light: false
    },
    location: {
      menu: true,
      toolbar: true,
      home: true
    }
  }
];

// Modules Library
const modulesLibrary = [];
const modules = require("@/modules/modules.js");

if (modules && Array.isArray(modules) && modules.length > 0) {
  for (let index = 0; index < modules.length; index++) {
    const moduleInstance = modules[index];
    if (moduleInstance && moduleInstance.enabled) {
      if (moduleInstance.name && moduleInstance.route && moduleInstance.menu) {
        const wrapInFolder = moduleInstance.menu.folder;
        if (wrapInFolder && !moduleInstance.menu.name && !moduleInstance.menu.icon) {
          continue;
        }
        const moduleLibrary = require("@/modules/" + moduleInstance.name + "/library.js").default;
        if (moduleLibrary && Array.isArray(moduleLibrary) && moduleLibrary.length > 0) {
          const filteredModuleLibrary = filterAddModuleName(
            moduleInstance.menu.name,
            moduleInstance.name,
            moduleLibrary
          );
          // Add authentication library
          // If myAccount is true
          addAuthenticationLibrary(moduleLibrary);
          if (wrapInFolder) {
            modulesLibrary.push({
              type: "folder",
              name: moduleInstance.menu.name,
              icon: moduleInstance.menu.icon,
              meta: moduleInstance.menu.meta,
              children: [...filteredModuleLibrary],
              model: moduleInstance.menu.expanded || false,
              moduleName: moduleInstance.name
            });
          } else {
            modulesLibrary.push(...filteredModuleLibrary);
          }
        }
      }
    }
  }
}

function addAuthenticationLibrary(library) {
  for (let item of library) {
    if (item && item.type === "folder" && item.children) {
      if (item.myAccount) {
        item.children.push(...filterAddModuleName("Single Sign-On", "_default", authLibrary));
        authLibraryAdded = true;
      } else {
        addAuthenticationLibrary(item.children);
      }
    }
  }
}

function filterAddModuleName(parentName, moduleName, moduleLibrary) {
  if (moduleName && moduleLibrary && moduleLibrary.length > 0) {
    for (let index = 0; index < moduleLibrary.length; index++) {
      let libraryObj = moduleLibrary[index];
      if (libraryObj) {
        libraryObj["moduleName"] = moduleName;
        libraryObj["parentName"] = parentName;
        if (libraryObj["children"] && libraryObj["children"].length > 0) {
          libraryObj["children"] = [
            ...filterAddModuleName(parentName, moduleName, libraryObj["children"])
          ];
        }
      }
    }
  }
  return moduleLibrary;
}

let configLibrary = [...filterAddModuleName("Home", "_default", homeLibrary), ...modulesLibrary];

if (!authLibraryAdded) {
  configLibrary = [
    ...configLibrary,
    ...filterAddModuleName("Single Sign-On", "_default", authLibrary)
  ];
}

module.exports = configLibrary;
