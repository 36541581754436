import Vue from "vue";
import VueI18n from "vue-i18n";

// Use vue-i18n
Vue.use(VueI18n);

// Check configuration
const config = require("@/config.asyent.js");
// Supported languages
const supportedLanguages = config.i18n.supportedLanguages;
// Default language
const defaultLanguage = config.i18n.defaultLanguage;

// Fetch locale files
const modules = require("@/modules/modules.js");

const messages = {};

for (let lang of Object.keys(supportedLanguages)) {
  // Load common languages
  let langFile1 = undefined;
  try {
    langFile1 = require(`@/i18n/${lang}.json`);
  } catch (e) {
    langFile1 = require(`@/i18n/${lang}.js`).default;
  }
  // Load vuetify languages
  let langFile2 = undefined;
  try {
    langFile2 = require(`@/i18n/vuetify/${lang}.json`);
  } catch (e) {
    langFile2 = require(`@/i18n/vuetify/${lang}.js`).default;
  }
  // Load vee-validate languages
  let langFile3 = undefined;
  try {
    langFile3 = require(`@/i18n/vee-validate/${lang}.json`);
  } catch (e) {
    langFile3 = require(`@/i18n/vee-validate/${lang}.js`).default;
  }
  // Reset if undefined
  if (!langFile1) {
    langFile1 = {};
  }
  if (!langFile2) {
    langFile2 = {};
  }
  if (!langFile3) {
    langFile3 = {};
  }
  messages[lang] = { ...langFile1, ...langFile2, ...langFile3 };
}

// Load all locales and remember context
if (modules && Array.isArray(modules) && modules.length > 0) {
  for (let index = 0; index < modules.length; index++) {
    const moduleInstance = modules[index];
    if (moduleInstance && moduleInstance.enabled) {
      if (moduleInstance.enabled && moduleInstance.name && moduleInstance.i18n) {
        for (let lang of Object.keys(supportedLanguages)) {
          // Import each language from each module
          messages[lang][moduleInstance.name] = moduleInstance.i18n[lang];
        }
      }
    }
  }
}

// Initiate a new instance of vue-i18n
export const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  warnHtmlInMessage: "off",
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages
});
