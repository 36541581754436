const moduleRoute = require("./module.js").route;

export default [
  {
    type: "folder",
    name: "Postal Cargo"
  },
  {
    type: "route",
    name: "Postal Consignments",
    description: "Manage Consignments",
    icon: "mdi-package",
    url: moduleRoute + "/consignments",
    meta: {
      color: "orange",
      light: true
    },
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  }
];
